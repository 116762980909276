import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
      <Layout customWidth="blogWidth">
    <div className="blog-post-container">
      <div className="blog-post">
        <h1 className="docsTitle">{frontmatter.title}</h1>
        <h2 className="docsDate">{frontmatter.date}</h2>
        <p className="docsTag">

        {/*Iterating over the tags */}
        {frontmatter.tags.map((tag, i) => (
            <Link key={i} to={"/tags/" + tag} >#{tag}  </Link>

        ))}
        </p>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        tags
      }
    }
  }
`